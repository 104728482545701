<template>
  <v-footer
    class="py-4"
    dark
    height="auto"
  >
    <v-container class="mx-auto">
      <v-row>
        <v-col cols="12" class="text-center">
          <span>contact@xterra.group <span style="color:#cbaa5c"><b>|</b></span> +48 696 963 963 <span style="color:#cbaa5c"><b>|</b></span> ul. Heweliusza 5b/22, 60-281 Poznań <span style="color:#cbaa5c"><b>|</b></span> al. Jerozolimskie 65/79, Centrum LIM 17 piętro, 00-697 Warszawa</span>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'CoreFooter',
  }
</script>
